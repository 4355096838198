<template>
    <v-dialog 
        v-if="Object.keys(selectedIssue).length !== 0"
        v-model="openIssueModalUpd"
        width="750"
        @click:outside="closeIssue"
    >
    <v-card>
            <!-- Tabs + Card Header -->
            <v-card-title 
            class="white--text primary"
            >
            Milestones for {{ selectedBurst.name}} of {{selectedCampaign.name}}
            </v-card-title>
            <v-tabs
                v-model="selectedTab"
                fixed-tabs
                background-color="primary"
                show-arrows
            >
                <v-tab
                    v-for="item in formatWorkflow"
                    :key="item.id"
                    :value="item.name"
                    @click="selectedTab = item.id"
                >
                    {{ item.n }}
                </v-tab>
            </v-tabs>
        <!-- Tab Content -->
        <v-card-text>
            <v-tabs-items v-model="selectedTab">
                <IssueModelTabItem 
                    v-for="task in formatWorkflow"
                    :key="task.id"
                    :formatWorkflow="formatWorkflow"
                    :currentBurst="selectedBurst"
                    :task="task"
                />
            </v-tabs-items>
        </v-card-text>
        <!-- Tab/Card Actions-->
    </v-card>
    </v-dialog>
</template>
<script>
// Components
import IssueModelTabItem from './IssueModelTabItem.vue'
export default {
    name: 'ProgressIssueModal',
    components: {
        IssueModelTabItem,
    },
    inject: ['closeIssue'],
    data: () => ({
        selectedTab: 0,
    }),
    props: {
        selectedIssue: {
            type: Object,
            required: true,
        },
        openIssueModal: {
            type: Boolean,
            default: () => false,
            required: true,
        },
        selectedBurst: {
            type: Object,
            default: () => {},
            required: true
        },
        selectedCampaign: {
            type: Object,
            default: () => {},
            required: true
        },
        issueIndex: {
            type: Number,
            default: () => 0,
            required: true
        }
    },
    computed: {
        // Modal controller
        openIssueModalUpd: {
            get() {
                return this.openIssueModal
            },
            set() {
                this.closeIssue()
            },
        },
        selectedTabController: {
            get() {
                return this.selectedTab
            },
            set() {
                this.selectedTab = this.issueIndex
            }
        },
        formatWorkflow(){
                if(this.selectedBurst.workflowStatus !== undefined) {
                if(this.selectedBurst.workflowStatus.categories) {
                let workflow = [...this.selectedBurst.workflowStatus.categories]
                // Format workflow.dIssues to be compatible with v-treeview, where title is the issue name and children are the tasks, where the name of the children is the name of the screen where children id matches the this.selectedBurst.campaignburstScreens.id
                workflow.forEach((category) => {
                    category.dIs.forEach((issue) => {
                        // Set the issue id to the issue id, and the name to the issue title
                        issue.name = issue.title
                        issue.children = [...issue.tasks]
                        issue.children.forEach((task) => {
                            // Find the screen where the task id matches the screen id
                            let screen = this.selectedBurst.campaignBurstScreens.find((screen) => {
                                return screen.id === task.cbsId
                            })
                            // Set the task id to the issue id + screen id, and the name to the issue title + screen name + media owner name
                            task.id = task.diId+'-'+task.cbsId
                            task.name = issue.title + ' / ' + (screen.screen.name || screen.screen.n) + ' - ' + (screen.screen.mo ? screen.screen.mo.fn : screen.screen.mediaOwner.friendlyName)
                        })
                    })
                })
                return workflow
            } 
            }
            return []
        }
    },
    mounted() {
        if(this.selectedIssue !== undefined)
            this.selectedTab = this.issueIndex
    },
}
</script>