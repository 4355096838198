var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tab-item',{key:_vm.task.id},[_c('v-card',{attrs:{"color":"basil","flat":""}},[_c('v-card-text',[(_vm.canEdit)?_c('v-treeview',{attrs:{"selectable":"","activatable":"","selected-color":"primary","items":_vm.task.dIs,"return-object":""},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [(!leaf && _vm.canEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":hover ? 'red darken-4' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Delete task")])]):_vm._e()]}}],null,false,4282823318),model:{value:(_vm.selectedCheckbox),callback:function ($$v) {_vm.selectedCheckbox=$$v},expression:"selectedCheckbox"}}):_c('v-treeview',{attrs:{"items":_vm.task.dIs,"activatable":"","selected-color":"primary","return-object":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticStyle:{"margin":"-1px 0px -17px -5px"},attrs:{"input-value":_vm.isSelected(item),"indeterminate":_vm.isIndeterminate(item),"disabled":"","color":"primary"}})]}}])})],1)],1)],1),(_vm.confirmDeleteModal)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.confirmDeleteModal),callback:function ($$v) {_vm.confirmDeleteModal=$$v},expression:"confirmDeleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"background-color":"#4AA6D5"}},[_vm._v("Delete Task")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Are you sure you want to delete '"+_vm._s(_vm.selectedIssue.name)+"' ? ")]),_c('v-card-actions',{staticClass:"pb-6 pt-4"},[_c('v-col',{staticClass:"text-center ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deleteIssue(_vm.selectedIssue)}}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){_vm.confirmDeleteModal = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }